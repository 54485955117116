import Heading from '../common/heading/Heading';
import classes from './ProjectsSection.module.css';
import { PROJECT_DATA } from '../../data/ProjectData';
import ProjectCard from './ProjectCard';

const ProjectSection = () => {
  return (
    <section className={classes.projectSection}>
      <div className={classes.container}>
        <Heading text={'Projects'} />
        <div className={classes.infoContainer}>
          {PROJECT_DATA.map((project) =>  
          <ProjectCard 
            projectName={project.projectName}
            description={project.description}
            image={project.image}
            githubLink={project.githubLink}
            webLink={project.webLink}
            technologies={project.technologies}
          />
          )}
        </div>
      </div>
    </section>
  )
}

export default ProjectSection;
import { useContext } from 'react';
import './App.css';
import Portfolio from './components/portfolio/Portfolio';
import { themeContext } from './context/ThemeProvider';
import './theme.css';

function App() {

  const { theme } = useContext(themeContext);
  return (
    <div className={`App ${theme}`}>
      <Portfolio />
    </div>
  );
}

export default App;

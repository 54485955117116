import classes from './Navbar.module.css';
import NavList from './components/NavList';
import logo from '../../assets/logo.png'
import { useCallback, useContext, useEffect, useState } from 'react';
import { themeContext } from '../../context/ThemeProvider';
import ThemeIcon from './components/ThemeIcon';
import { NAVBAR_HEIGHT } from './constants';
import Link from 'react-scroll/modules/components/Link';
import Hamburger from './constants/icons/Hamburger';
import MobileNavbar from './components/mobileNavbar';

const duration = 1000;

const NavBar = () => {
  const {theme, toggleTheme} = useContext(themeContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  //  To close the menu if menu is open and 
  //  screen width become greater than 768px
  useEffect(() => {
    // try to create a hook
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  useEffect(() => {
    if (windowWidth > 768) {
      setMenuOpen(false);
    }
  }, [windowWidth])

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [menuOpen])

  const handleMenuOpen = useCallback(() => {
    setMenuOpen((prevState) => {
      return !prevState;
    })
  }, [setMenuOpen])

  return (
    <section className={classes.navbarSection} style={{minHeight: `${NAVBAR_HEIGHT}`}}>
      <div className={classes.navbarContainer}>
          <Link to={`home`} smooth={true} duration={duration} offset={-NAVBAR_HEIGHT} >
            <div className={classes.logoContainer} onClick={() => {}}>
              <img src={logo} className={classes.logo} alt={'Paras Verma logo'}/>
              <span className={classes.name}>Paras</span>
            </div>
          </Link>
          <div className={classes.rightContainer}>
            <div className={classes.navListContainer}>
              <NavList />
            </div>
            <div className={classes.themeSwitchContainer}>
              <ThemeIcon theme={theme} onClick={toggleTheme} />
            </div>
            <div className={classes.burgerIcon} onClick={handleMenuOpen}>
              <Hamburger active={menuOpen} />
            </div>
          </div>
        </div>
        {menuOpen && 
          <MobileNavbar onOverlayClick={handleMenuOpen} onNavItemClick={handleMenuOpen} />
        }
    </section>
  )
}

export default NavBar;
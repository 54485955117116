import { ICON_PATH_DATA } from '../../data/iconsPathData';
import IconSVG from '../common/icon/IconSVG';
import Tag from '../common/tag/Tag';
import classes from './ProjectCard.module.css';

const ProjectCard = (props) => {
  const { projectName, image, description, 
     githubLink, webLink, technologies } = props;
  return (
    <div className={classes.container}>
      <div className={classes.projectImage}>
        <img src={image} className={classes.image} alt={projectName} />
      </div>
      <div className={classes.projectInfo}>
        <div className={classes.projectHeader}>
          <div className={classes.projectTitle}><p>{projectName}</p></div>
          <div className={classes.projectLinks}>
            <IconSVG 
              link={githubLink} 
              width={'1.5rem'} 
              height={'1.5rem'} 
              pathData={ICON_PATH_DATA.GITHUB.PATH_DATA} 
            />
            <IconSVG 
              link={webLink} 
              width={'1.5rem'} 
              height={'1.5rem'} 
              pathData={ICON_PATH_DATA.EXTERNAL_LINK.PATH_DATA} 
              viewBoxData={ICON_PATH_DATA.EXTERNAL_LINK.VIEW_BOX}
            />
          </div>
        </div>
        <div className={classes.projectDescription}>
          <p>{description}</p>
        </div>
        <div className={classes.technologies}>
          {technologies.map((tech) => 
            <Tag text={tech}/>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProjectCard;
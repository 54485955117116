import { Element } from 'react-scroll/modules';

import AboutMeSection from '../about-me-section/AboutMeSection';
import BottomWaveBorder from '../common/wave/BottomWaveBorder';
import TopWaveBorder from '../common/wave/TopWaveBorder';
import ContactSection from '../contact-section/ContactSection';
import ExperienceSection from '../experience-section/ExperienceSection';
import Footer from '../footer/Footer';
import HeadSection from '../head-section/HeadSection';
import NavBar from '../navbar/Navbar';
import ProjectSection from '../projects-section/ProjectsSection';
import classes from './Portfolio.module.css';

const Portfolio = () => {
  return (
    <div className={classes.mainContainer}>
      <NavBar />
      <Element name='home'>
        <HeadSection />
      </Element>
      <Element name='about'>
        <AboutMeSection />
      </Element>
      <TopWaveBorder />
      <Element name='experience'>
        <ExperienceSection />
      </Element>
      <Element name='projects'>
        <ProjectSection />
      </Element>
      <BottomWaveBorder />
      <Element name='contact'>
        <ContactSection />
      </Element>
      <Footer />
    </div>
  )
}

export default Portfolio;
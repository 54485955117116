import classes from './Heading.module.css';

const Heading = (props) => {
  return (
    <div className={classes.heading}>
      <span>{props.text}</span>
      <hr />
    </div>
  )
}

export default Heading;
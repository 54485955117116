
const PROJECT_DATA = [
  {
    image: '/assets/calculatorPro.png',
    projectName: 'Calculator Pro',
    githubLink: 'https://github.com/parasverma02/calculator-pro',
    webLink: 'https://calculator-pro-olive.vercel.app/',
    description:'Calculator Pro is an online calculator/converter tool. Among calculators, there\'s a basic calculator, which perform maths calculations, and various other financial calculator as well. It also has a measurement unit conversion tool and a currency conversion tool which provides live foreign exchange rates. The UI of all these calculators/converters is similar to that of a digital calculator',
    technologies: ['React', 'Javascript', 'Node', 'HTML', 'CSS']
  },
  {
  image: '/assets/portfolio.png',
  projectName: 'Portfolio',
  githubLink: 'https://github.com/parasverma02/portfolio',
  webLink: 'https://parasverma.dev/',
  description:'This is the first version of my portfolio website. The main purpose of this project is to get better understanding of the design aspect of a website using Figma design tool. I am regularly making minor change on the design of this site to make it look more appealing.',
  technologies: ['React', 'Javascript', 'Node', 'HTML', 'CSS', 'Figma']
  },
]

export { PROJECT_DATA };